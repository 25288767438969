import instituteLogo from "../../assets/al_karamah_logo.png";

const Login = () => {
	const contentURL = process.env.REACT_APP_CONTENT_URL;
	const assessmentURL = process.env.REACT_APP_ASSESSMENT_URL;

	const openContentPortal = (e) => {
		e.preventDefault();
		window.location.href = contentURL;
	};

	const openAssessmentPortal = (e) => {
		e.preventDefault();
		window.location.href = assessmentURL;
	};

	return (
		<div className="row g-0 auth-wrapper">
			<div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
				<div className="auth-background-holder"></div>
				<div className="auth-background-mask"></div>
			</div>

			<div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
				<div className="d-flex flex-column align-content-end">
					<div className="auth-body mx-auto">
						<img
							src={instituteLogo}
							className="auth-logo"
							alt="institute logo"
						/>

						<p>Il Caffè di Roma Training App</p>
						<div className="auth-form-container text-start">
							<hr />
							<div className="text-center">
								<button
									onClick={openContentPortal}
									className="btn btn-primary w-100 theme-btn mx-auto"
								>
									Open Content Portal
								</button>
							</div>
							<hr />
							<div className="text-center">
								<button
									onClick={openAssessmentPortal}
									className="btn btn-primary w-100 theme-btn mx-auto"
								>
									Open Assessments Portal
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
