import Home from "../components/views/Home";
import Login from "../components/views/Login";
import Forgot from "../components/views/Forgot";
import Download from "../components/views/Download";
import NotFound from "../components/views/NotFound";

const routes = [
	{
		path: "/",
		component: Home,
		isPrivate: false,
	},
	{
		path: "/*",
		component: NotFound,
		isPrivate: false,
	},
];

export default routes;
