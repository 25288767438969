// Context/actions.js

const ROOT_URL = process.env.REACT_APP_API_URL;

export async function loginUser(dispatch, loginPayload) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(loginPayload),
	};

	try {
		dispatch({ type: "REQUEST_LOGIN" });
		let response = await fetch(`${ROOT_URL}/signin`, requestOptions);
		let data = await response.json();
		if (data.accessToken) {
			dispatch({ type: "LOGIN_SUCCESS", payload: data });
			localStorage.setItem("currentUser", JSON.stringify(data));
			return data;
		}

		dispatch({ type: "LOGIN_ERROR", error: "Incorrect email or password!" });
		return;
	} catch (error) {
		dispatch({ type: "LOGIN_ERROR", error: "Incorrect email or password!" });
	}
}

export async function logout(dispatch) {
	dispatch({ type: "LOGOUT" });
	localStorage.removeItem("currentUser");
	localStorage.removeItem("accessToken");
}
