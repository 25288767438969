// Context/reducer.js

let user = localStorage.getItem("currentUser")
	? JSON.parse(localStorage.getItem("currentUser")).user
	: "";
let accessToken = localStorage.getItem("currentUser")
	? JSON.parse(localStorage.getItem("currentUser")).accessToken
	: "";

export const initialState = {
	userDetails: "" || user,
	accessToken: "" || accessToken,
	loading: false,
	errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
	switch (action.type) {
		case "REQUEST_LOGIN":
			return {
				...initialState,
				loading: true,
			};
		case "LOGIN_SUCCESS":
			return {
				...initialState,
				user: action.payload.email,
				accessToken: action.payload.accessToken,
				errorMessage: action.error,
				loading: false,
			};
		case "LOGOUT":
			return {
				...initialState,
				user: "",
				accessToken: "",
			};

		case "LOGIN_ERROR":
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
