import instituteLogo from "../../assets/al_karamah_logo.png";

const NotFound = (props) => {
	return (
		<div className="row g-0 auth-wrapper">
			<div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
				<div className="auth-background-holder"></div>
				<div className="auth-background-mask"></div>
			</div>

			<div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
				<div className="d-flex flex-column align-content-end">
					<div className="auth-body mx-auto">
						<img
							src={instituteLogo}
							className="auth-logo"
							alt="institute logo"
						/>
						<p>404: Not found!</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
