import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useAuthState } from "../context/context";

const AppRoute = ({ component: Component, path, isPrivate, ...rest }) => {
	const userDetails = useAuthState();
	return (
		<Route
			path={path}
			render={(props) =>
				isPrivate && !Boolean(userDetails.accessToken) ? (
					<Redirect to={{ pathname: "/" }} />
				) : (
					<Component {...props} />
				)
			}
			{...rest}
		/>
	);
};

export default AppRoute;
